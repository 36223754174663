<template>
  <div class="page">
    <div class="title">权限控制</div>
    <el-divider></el-divider>
    <el-form>
      <el-form-item>
        <el-checkbox-group v-model="checklist" @change="navtitle">
          <!-- <el-checkbox label="工作台"></el-checkbox> -->
          <el-checkbox label="企业"></el-checkbox>
          <el-checkbox label="员工"></el-checkbox>
          <el-checkbox label="合同"></el-checkbox>
          <el-checkbox label="招聘"></el-checkbox>
          <el-checkbox label="发薪"></el-checkbox>
          <el-checkbox label="审核"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="企业">
        <el-checkbox-group v-model="checklist1" @change="navtitle1">
          <el-checkbox label="enterprise_infomation">企业信息</el-checkbox>
          <el-checkbox label="enterprise_management">企业管理</el-checkbox>
        </el-checkbox-group>
        <div>企业信息</div>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="Infomations">基础信息</el-checkbox>
          <el-checkbox label="Authentication">认证信息</el-checkbox>
          <el-checkbox label="Notice">企业公告</el-checkbox>
        </el-checkbox-group>
        <div>企业管理</div>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="DepartmentList">部门管理</el-checkbox>
          <el-checkbox label="StationList">岗位管理</el-checkbox>
          <el-checkbox label="Rolemanagement">角色管理</el-checkbox>
          <el-checkbox label="labourServices">劳务管理</el-checkbox>
          <el-checkbox label="InsurancesAndfund">五险一金</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="员工">
        <el-checkbox-group v-model="checklist1" @change="navtitle1">
          <el-checkbox label="employee_information">员工信息</el-checkbox>
          <el-checkbox label="employee_relations">员工申请</el-checkbox>
        </el-checkbox-group>
        <div>员工信息</div>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="EmployeeRoster">员工花名册</el-checkbox>
          <el-checkbox label="EntryManagement">入职管理</el-checkbox>
          <el-checkbox label="RegularManagement">转正管理</el-checkbox>
          <el-checkbox label="LeaveManagement">离职管理</el-checkbox>
          <el-checkbox label="whiteNoteReview">白条审核</el-checkbox>
          <el-checkbox label="employeePostTransfer">调岗管理</el-checkbox>
          <el-checkbox label="employeeBorrow">借岗管理</el-checkbox>
        </el-checkbox-group>
        <!-- <div>员工申请</div>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="applicationForm">申请表单</el-checkbox>
          <el-checkbox label="applicationRecord">申请记录</el-checkbox>
        </el-checkbox-group> -->
      </el-form-item>
      <el-form-item label="合同">
        <el-checkbox-group v-model="checklist1" @change="navtitle1">
          <el-checkbox label="contract_management">合同管理</el-checkbox>
        </el-checkbox-group>
        <div>合同管理</div>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="StandardContractsList">标准合同列表</el-checkbox>
          <!-- <el-checkbox label="SignatureRecord">签署记录</el-checkbox> -->
          <el-checkbox label="EmployeeContractInformation"
            >员工合同信息</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="招聘">
        <el-checkbox-group v-model="checklist1" @change="navtitle1">
          <el-checkbox label="recruitment_management">招聘管理</el-checkbox>
          <el-checkbox label="recruit_workers">招聘人员</el-checkbox>
          <el-checkbox label="recruitment_overview">概览</el-checkbox>
        </el-checkbox-group>
        <div>招聘管理</div>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="RecruitmentPost">招聘职位</el-checkbox>
          <el-checkbox label="RecruitmentTeam">招聘团队</el-checkbox>
        </el-checkbox-group>
        <div>招聘人员</div>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="Recruiting">招聘中</el-checkbox>
          <el-checkbox label="Eliminated">已淘汰</el-checkbox>
        </el-checkbox-group>
        <div>概览</div>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="RecruitmentOverview">概览</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="发薪">
        <el-checkbox-group v-model="checklist1" @change="navtitle1">
          <el-checkbox label="salary_management">发薪管理</el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="MonthlyAttendanceManagement"
            >月考勤管理</el-checkbox
          >
          <el-checkbox label="MonthlyWageStatistics">月工资统计</el-checkbox>
          <!-- <el-checkbox label="SalarySplit">计薪规则</el-checkbox>
          <el-checkbox label="SalaryAllocation">薪酬配置</el-checkbox> -->
          <el-checkbox label="PayrollRecord">薪资发放记录</el-checkbox>
        </el-checkbox-group>
        <div>审批管理</div>
        <el-checkbox-group v-model="checklist1" @change="navtitle1">
          <el-checkbox label="approval_management">审批管理</el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-model="checklist2">
          <el-checkbox label="ApprovalForms">审批表单</el-checkbox>
          <el-checkbox label="ApprovalTemplateLibrary">审批模板</el-checkbox>
          <el-checkbox label="ApprovalLog">审批记录</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item class="form_btns">
        <div class="form_btns">
          <div class="custom_button plain no_select" @click="submit">保存</div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      checklist: ["工作台", "企业", "员工", "合同", "招聘", "发薪", "审核"],
      checklist1: [
        "enterprise_infomation",
        "enterprise_management",
        "employee_information",
        "employee_relations",
        "contract_management",
        "recruitment_management",
        "recruit_workers",
        "recruitment_overview",
        "salary_management",
        "approval_management",
      ],
      checklist2: [],
      role_id: "",
      id: "",
    };
  },
  async created() {
    this.role_id = await this.$route.params.role_id;
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions("enterprise", ["auth_manage", "get_authlist"]),

    navtitle(e) {
      console.log(e);
    },

    navtitle1(e) {
      console.log(e);
    },

    navtitle3(e) {
      console.log(e);
    },

    submit() {
      console.log(this.checklist);
      let datakey = {
        header: JSON.stringify(this.checklist),
        first_nav: JSON.stringify(this.checklist1),
        second_nav: JSON.stringify(this.checklist2),
        role_id: this.role_id,
        id: this.id ? this.id : "",
      };
      let loading = this.$loading();
      this.auth_manage(datakey)
        .then((res) => {
          console.log(res);
          this.$message.success(res.data.msg);
          setTimeout(() => {
            loading.close();
            this.$router.back();
          }, 2000);
        })
        .catch((e) => {
          loading.close();
          console.error(e);
          this.$message.error(e.msg);
        });
    },

    loadDataFactory() {
      let params = {};
      return () => {
        params.role_id = this.role_id;
        let loading = this.$loading();
        this.get_authlist(params)
          .then((res) => {
            console.log("datatatatta", res);
            this.id = res.data.data.id;
            this.checklist = res.data.data.header;
            this.checklist1 = res.data.data.first_nav;
            this.checklist2 = res.data.data.second_nav;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.form_btns {
  display: flex;
  align-items: center;

  .custom_button {
    width: 142px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }

  > div:not(:first-child) {
    margin-left: 20px;
  }
}
</style>